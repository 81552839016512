import { Box, Button, Heading } from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";
import React from "react";
import { CookiesProvider } from "react-cookie";
import Hero from "../components/Hero";
import Layout from "../components/Layout";

const Error404: React.FC = () => {
  return (
    <CookiesProvider>
      <Layout>
        <Hero h={300} textColor={"utils.white"}>
          <Heading as={"h1"} fontSize={38} mb={3}>
            Pagina non trovata!
          </Heading>
          <Box mt={10}>
            <Button
              as={GatsbyLink}
              to="/"
              colorScheme="tim"
              borderRadius={0}
              py={8}
              px={20}
              bgColor="accent.01"
              textTransform="uppercase"
            >
              Continua a navigare
            </Button>
          </Box>
        </Hero>
      </Layout>
    </CookiesProvider>
  );
};

export default Error404;
